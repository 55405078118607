<template>
  <v-card rounded="lg" flat max-width="500" class="pa-md-12 pa-4 pb-md-5 width100" min-height="448">
    <div @click="toLogin" class="link primary--text text--lighten-1 mb-7">
      <v-icon color="primary lighten-1">mdi-chevron-left</v-icon> Back to Log In
    </div>
    <div class="f32 mb-1">Password recovering</div>
    <div class="mb-8 text--text">
      Please enter a new password. Password should be at least 8 characters long and contains symbols and digits Update
    </div>
    <v-form @submit.prevent="action" ref="form" lazy-validation>
      <v-text-field
        class="field46"
        v-model="password"
        placeholder="New password"
        :error-messages="passwordErrors"
        :type="show ? 'text' : 'password'"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show = !show"
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-text-field>
      <v-btn depressed type="submit" block large color="primary">Update</v-btn>
    </v-form>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      password: '',
      show: false,
      error: [],
    };
  },
  methods: {
    notifi() {
      this.$notify({
        title: 'Something went wrong!',
        message: 'We couldn’t find an account matching the password you entered.',
        type: 'error',
        horizontalAlign: 'center',
        verticalAlign: 'top',
      });
    },
    toLogin() {
      this.$router.push('/login');
    },
    async action() {
      this.error = [];
      const data = new Object();
      data.token = this.$route.query.t;
      data.password = this.password;
      await this.$store
        .dispatch('resetPassword', data)
        .then((res) => {
          if (res.data.auth_token) {
            localStorage.setItem('jwt_token', res.data.auth_token);
            this.$router.push('/login?c=OTP');
          } else if (res.data.access_token) {
            localStorage.setItem('jwt_token', res.data.access_token);
            setTimeout(() => {
              this.$router.push('/');
            }, 500);
          }
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    loginError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'credential__invalid')) {
        this.notifi();
      } else if (this.error.find((item) => item == 'staff__suspended')) {
        this.$router.push('/blocked');
      } else if (this.error.find((item) => item == 'token__expired')) {
        this.$router.push('/expired-password');
      } else if (this.error.find((item) => item == 'token__already_used')) {
        this.$router.push('/recovery-password-already');
      } else if (e.response.status == 403) {
        this.$router.push('/recovery-error');
      }
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push('Please enter your password');
      this.error.find((item) => item == 'password__invalid') && errors.push('Provided password is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>
